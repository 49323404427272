import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import reactStringReplace from "react-string-replace"
import LazyImage from "../components/lazyimage"
import Sticky from 'react-stickynode';
import { isMobile, unorphan } from '../components/utilities.js'




class TitlePageTemplate extends Component {
  constructor(props) {
    super(props);
    this.currentPage = this.props.data.wordpressWpTitles

    this.state = { width: 0, height: 0 }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }


  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const {
      title,
      slug, acf,
      people,
      content
    } = this.currentPage

    const {
      attribution,
      isbn, book_format,
      dap_link,
      cover_image,
      interior_images,
      publish_date,
      dimensions,
      designer,
      price
    } = acf

    const allPeople = this.props.data.allWordpressWpPeople
    // clone attribution
    let linkedAttribution = (' ' + attribution).slice(1);

    people.forEach(person => {
      const node = allPeople.edges.filter(({ node }) => node.wordpress_id === person)[0].node
      linkedAttribution = reactStringReplace(linkedAttribution, node.name, (match, i) => (

        <Link key={"person" + node.wordpress_id} to={"/people/" + node.slug}>{match}</Link>
      ));
    });



    //const bg = cover_image.localFile.childImageSharp.fluid.base64


    return (
      <div title={title} >

       

        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Gregory R. Miller & Co. – " + title}</title>
          <link
            rel="canonical"
            href={"https://grmandco.com/titles/" + slug}
          />
        </Helmet>



        <h1 className="page_title" dangerouslySetInnerHTML={{ __html: unorphan(title) }} />




        <div className="main_content">



          <div className="cover_image fitter">
            <div className="cover"
            
            style={{
              display: "block",
              paddingTop: ((dimensions.height / dimensions.width) * 100) + "%"

          }}>
              <LazyImage image={cover_image} alt="" sizes="(min-width: 800px) 50vw, 100vw" ></LazyImage>


            </div>
          </div>
          <div className="book_content" id="book_content" dangerouslySetInnerHTML={{ __html: content }} />


          <style>


          </style>


        </div>

        <div className="aux_content book_details">
          <div className="pusher">
            <Sticky top={130} bottomBoundary='#book_content' enabled={isMobile(this.state.width)}>
              {linkedAttribution && <div className="aux"> {linkedAttribution}</div>}

              {publish_date && <div className="aux"> Published {publish_date.toString().substr(0,4)}</div>}
              {designer && <div className="aux format">DESIGN: {designer}</div>}
              {book_format && <div className="aux format">FORMAT: {book_format}</div>}
              {isbn && <div className="aux isbn">ISBN: {isbn}</div>}
              {price && <div className="aux price">PRICE: ${price}</div>}
              
              {dap_link && <div className="aux"><a href={dap_link}>Order from D.A.P.</a></div>}
            </Sticky>
          </div>
        </div>

        
        <div className="interior_images" id="interior_images">
          {interior_images && interior_images.length && interior_images.map(image => (
            <div>
            <LazyImage image={image} alt="test" sizes="100vw"></LazyImage>
            </div>
          ))}
        </div>

      </div>
    )
  }
}

export default TitlePageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpTitles(id: { eq: $id }) {
      title
      content
      slug
      people
      date(formatString: "MMMM DD, YYYY")

      acf {
        publish_date
        isbn
        dap_link
        attribution
        book_format
        price
        designer
        dimensions {
          width
          height
        }
        cover_image {
          id
          source_url
          media_details {
            width
            height
            file
            sizes {
              tiny {
                file
                width
                height
                mime_type
                source_url
              }    
              thumbnail {
                file
                width
                height
                mime_type
                source_url
              } 
              medium {
                file
                width
                height
                mime_type
                source_url
              }
              medium_large {
                file
                width
                height
                mime_type
                source_url
              }
              large {
                file
                width
                height
                mime_type
                source_url
              }
              full{
                file
                width
                height
              source_url

              }
            }
          }
        }
        interior_images {
          id
          source_url
          media_details {
            width
            height
            file
            sizes {
              tiny {
                file
                width
                height
                mime_type
                source_url
              }    
              thumbnail {
                file
                width
                height
                mime_type
                source_url
              } 
              medium {
                file
                width
                height
                mime_type
                source_url
              }
              medium_large {
                file
                width
                height
                mime_type
                source_url
              }
              large {
                file
                width
                height
                mime_type
                source_url
              }
              full{
                file
                width
                height
              source_url

              }
              
              
            }
          }
        }
      }

    }
    allWordpressWpPeople {
      edges {
        node {
          id
          name
          slug
          wordpress_id
        }
      }
    }

    allWordpressWpBookCategory {
      edges {
        node {
          name
          wordpress_id
        }
      }
    }

    
  }
`

